import {
  Box,
  Center,
  Flex,
  Heading,
  Select,
  SimpleGrid,
  Spacer,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import Layout from "../../components/Layout";
import ToolboxItemCard from "../../components/ToolboxItemCard";
import { ToolboxItem, useToolbox } from "../../data/toolboxApi";
import SearchComponent from "../../components/SearchComponent";
import InstructionText from "../../components/InstructionText";

const ToolboxList = ({
  data,
  refetch,
  isLoading,
}: {
  data?: ToolboxItem[];
  refetch: () => void;
  isLoading: boolean;
}) => {
  const [isMobile] = useMediaQuery("(max-width: 540px)");

  useEffect(() => {
    document.title = "Toolbox";
  }, []);

  return (
    <SimpleGrid
      gap={5}
      templateColumns={[
        "1fr",
        "1fr",
        "repeat(2, 1fr)",
        "repeat(2, 1fr)",
        "repeat(3, 1fr)",
      ]}
    >
      {data && data.length > 0 ? (
        data.map((toolboxItem) => (
          <ToolboxItemCard
            item={toolboxItem}
            toolBoxRefetch={refetch}
            toolBoxLoading={isLoading}
            toolBoxData={data}
            compact={isMobile}
          />
        ))
      ) : data && data.length === 0 ? (
        <Box opacity={0.6}>No items found</Box>
      ) : (
        <Spinner />
      )}
    </SimpleGrid>
  );
};

const Toolbox = () => {
  const { data, loading: isLoading, fetchData: refetch } = useToolbox(); // TODO: review

  const [isMobile] = useMediaQuery("(max-width: 540px)");
  const [dropDownFilter, setDropDownFilter] =
    React.useState<string>("highlight");

  const cardBg = useColorModeValue("white", "gray.800");

  const tabs = ["highlight", "favourite", "resource"];

  const description =
    dropDownFilter === "highlight"
      ? "Here is where you'll find all the text you highlighted as important as you read through the website"
      : dropDownFilter === "favourite"
      ? "Here is where you'll find easy access to all the pages your favourited"
      : dropDownFilter === "resource"
      ? "Here is where you'll find all the resources you have saved from the resources page"
      : "";

  useEffect(() => {
    refetch();
  }, []);

  let content = null;
  if (isMobile) {
    content = (
      <>
        <Select
          mb={2}
          value={dropDownFilter}
          onChange={(e) => {
            setDropDownFilter(e.target.value);
          }}
        >
          <option value="highlight">Highlights</option>
          <option value="favourite">Bookmarks</option>
          <option value="resource">Resources</option>
        </Select>
        <Text mb={3}>{description}</Text>
        <ToolboxList
          data={
            dropDownFilter == ""
              ? data
              : data?.filter((d) => d.type === dropDownFilter)
          }
          refetch={refetch}
          isLoading={isLoading}
        />
      </>
    );
  } else {
    content = (
      <Tabs
        variant="soft-rounded"
        colorScheme="blue"
        w="100%"
        index={tabs.indexOf(dropDownFilter)}
        onChange={(i) => setDropDownFilter(tabs[i])}
      >
        <TabList mb={3} overflowX="auto" fontSize={["xs", "sm", "md"]}>
          <Tab>Highlights</Tab>
          <Tab>Favourites</Tab>
          <Tab>Resources</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Text mb={4}>{description}</Text>
            <ToolboxList
              data={data?.filter((d) => d.type === "highlight")}
              refetch={refetch}
              isLoading={isLoading}
            />
          </TabPanel>
          <TabPanel>
            <Text mb={4}>{description}</Text>
            <ToolboxList
              data={data?.filter((d) => d.type === "favourite")}
              refetch={refetch}
              isLoading={isLoading}
            />
          </TabPanel>
          <TabPanel>
            <Text mb={4}>{description}</Text>
            <ToolboxList
              data={data?.filter((d) => d.type === "resource")}
              refetch={refetch}
              isLoading={isLoading}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    );
  }

  return (
    <main aria-label="Goals">
      <Layout>
        <Flex p={isMobile ? 3 : 0}>
          <Center w="full">
            <Heading as="h1" mb={3}>
              Toolbox
            </Heading>
            <Spacer />
            <InstructionText
              title={"About Toolbox"}
              mdId={"instruction-text/toolbox"}
            />
            {
              isMobile ? <SearchComponent asInput={false} dark={false} /> : null
              // <AddLink refetch={refetch} />
            }
          </Center>
        </Flex>
        <Flex
          flexDir="column"
          flex={1}
          rounded="lg"
          bg={cardBg}
          boxShadow="lg"
          p={isMobile ? 4 : 8}
        >
          {/* <Flex p={isMobile ? 3 : 0}>
            <Heading mb={6}>Toolbox</Heading>
            <Spacer />
            {
              isMobile ? <SearchComponent asInput={false} dark={true} /> : null
              // <AddLink refetch={refetch} />
            }
          </Flex> */}
          {/* {isMobile ? (
            <>
              <AddLink refetch={refetch} />
              <Box mb={2} />
            </>
          ) : null} */}

          {content}
        </Flex>
      </Layout>
    </main>
  );
};

export default Toolbox;
